@keyframes bg-animation013 {
  0% {
    background: #e9e9e9;
    width: 0;
  }

  100% {
    background: #41007f;
    width: 50%;
  }
}
@keyframes bg-animation13-1 {
  0% {
    background: #41007f;
    width: 50%;
  }

  100% {
    background: #41007f;
    width: 100%;
  }
}

@keyframes bg-animation01 {
  0% {
    background: #e9e9e9;
    width: 0;
  }

  100% {
    background: #41007f;
    width: 100%;
  }
}
@keyframes bg-animation02 {
  0% {
    background: #e9e9e9;
    width: 0;
  }

  100% {
    // background: linear-gradient(90deg,#f9004b 0%, #ff6c00 100%);
    background: #00d900;
    width: 100%;
  }
}
@keyframes bg-animation03 {
  0% {
    background: #e9e9e9;
    width: 0;
  }

  100% {
    // background: linear-gradient(90deg,#ff6c00 0%, #ffbd00 100%);
    background: #00d900;
    width: 100%;
  }
}
@keyframes bg-animation04 {
  0% {
    background: #e9e9e9;
    width: 0;
  }

  100% {
    // background: linear-gradient(90deg,#ffbd00 0%, #c1c700 24.14%, #00e500 100%);
    background: #00d900;
    width: 100%;
  }
}
@keyframes bg-animation05 {
  0% {
    // background: #00E500;
    background: #00d900;
    width: 0;
  }

  100% {
    // background: #00E500;
    background: #00d900;
    width: 100%;
  }
}
@keyframes bg-animation10 {
  0% {
    // background: #F9004B;
    background: #41007f;
    width: 100%;
  }

  100% {
    background: #41007f;
    width: 28.5%;
  }
}
@keyframes bg-animation11 {
  0% {
    // background: #F9004B;
    background: #00d900;
    width: 0%;
  }

  100% {
    // background: #F9004B;
    background: #00d900;
    width: 100%;
  }
}
@keyframes bg-animation12 {
  0% {
    background: #e9e9e9;
    width: 0%;
  }

  100% {
    // background: linear-gradient(90deg,#f9004b 0%, #ff6c00 100%);
    background: #00d900;
    width: 100%;
  }
}
@keyframes bg-animation13 {
  0% {
    background: #e9e9e9;
    width: 0%;
  }

  100% {
    // background: linear-gradient(90deg,#ff6c00 0%, #ffbd00 100%);
    background: #00d900;
    width: 100%;
  }
}
@keyframes bg-animation14 {
  0% {
    background: #e9e9e9;
    width: 0%;
  }

  100% {
    // background: linear-gradient(90deg,#ffbd00 0%, #c1c700 24.14%, #00e500 100%);
    background: #00d900;
    width: 100%;
  }
}
@keyframes bg-animation15 {
  0% {
    background: #e9e9e9;
    width: 0%;
  }

  100% {
    // background: #F9004B;
    background: #00d900;
    width: 100%;
  }
}
@keyframes bg-animation20 {
  0% {
    // background: linear-gradient(90deg,#f9004b 0%, #ff6c00 100%);
    background: #00d900;
    width: 100%;
  }

  100% {
    background: #e9e9e9;
    width: 0;
  }
}
@keyframes bg-animation21 {
  0% {
    // background: linear-gradient(90deg,#f9004b 0%, #ff6c00 100%);
    background: #00d900;
    width: 100%;
  }

  100% {
    // background: #F9004B;
    background: #00d900;
    width: 0;
  }
}
@keyframes bg-animation22 {
  0% {
    // background: linear-gradient(90deg,#f9004b 0%, #ff6c00 100%);
    background: #00d900;
    width: 0%;
  }

  100% {
    // background: linear-gradient(90deg,#f9004b 0%, #ff6c00 100%);
    background: #00d900;
    width: 100%;
  }
}
@keyframes bg-animation23 {
  0% {
    // background: linear-gradient(90deg,#f9004b 0%, #ff6c00 100%);
    background: #00d900;
    width: 0%;
  }

  100% {
    // background: linear-gradient(90deg,#ff6c00 0%, #ffbd00 100%);
    background: #00d900;
    width: 100%;
  }
}
@keyframes bg-animation24 {
  0% {
    // background: linear-gradient(90deg,#f9004b 0%, #ff6c00 100%);
    background: #00d900;
    width: 0%;
  }

  100% {
    // background: linear-gradient(90deg,#ffbd00 0%, #c1c700 24.14%, #00e500 100%);
    background: #00d900;
    width: 100%;
  }
}
@keyframes bg-animation25 {
  0% {
    // background: #00E500;
    background: #00d900;
    width: 0%;
  }

  100% {
    // background: #00E500;
    background: #00d900;
    width: 100%;
  }
}

#visibility_addInventory .visibility-case0-13 span:first-child:before {
  animation: bg-animation013 1s forwards;
}
#visibility_addInventory .visibility-case13-1 span:first-child:before {
  animation: bg-animation13-1 1s forwards;
}
#visibility_addInventory .visibility-case0-1 span:first-child:before {
  // animation: bg-animation01 1s forwards;
  animation: bg-animation13-1 1s forwards;
}
#visibility_addInventory .visibility-case0-2 span:first-child:before {
  animation: bg-animation13-1 0.5s forwards;
}
#visibility_addInventory .visibility-case0-2 span:nth-child(2):before {
  animation: bg-animation02 0.5s forwards;
  animation-delay: 0.35s;
}

#visibility_addInventory .visibility-case0-3 span:first-child:before {
  animation: bg-animation01 0.5s forwards;
}
#visibility_addInventory .visibility-case0-3 span:nth-child(2):before {
  animation: bg-animation02 0.5s forwards;
  animation-delay: 0.35s;
}
#visibility_addInventory .visibility-case0-3 span:nth-child(3):before {
  animation: bg-animation03 0.5s forwards;
  animation-delay: 0.7s;
}

#visibility_addInventory .visibility-case0-4 span:first-child:before {
  animation: bg-animation01 0.5s forwards;
}
#visibility_addInventory .visibility-case0-4 span:nth-child(2):before {
  animation: bg-animation02 0.5s forwards;
  animation-delay: 0.35s;
}
#visibility_addInventory .visibility-case0-4 span:nth-child(3):before {
  animation: bg-animation03 0.5s forwards;
  animation-delay: 0.7s;
}
#visibility_addInventory .visibility-case0-4 span:nth-child(4):before {
  animation: bg-animation04 0.5s forwards;
  animation-delay: 1.1s;
}

#visibility_addInventory .visibility-case0-5 span:nth-child(1):before {
  animation: bg-animation05 0.5s forwards;
  // background: #00E500;
}
#visibility_addInventory .visibility-case0-5 span:nth-child(2):before {
  animation: bg-animation05 0.5s forwards;
  animation-delay: 0.35s;
}
#visibility_addInventory .visibility-case0-5 span:nth-child(3):before {
  animation: bg-animation05 0.5s forwards;
  animation-delay: 0.7s;
}
#visibility_addInventory .visibility-case0-5 span:nth-child(4):before {
  animation: bg-animation05 0.5s forwards;
  animation-delay: 1.1s;
}
#visibility_addInventory .visibility-case0-5 span:nth-child(5):before {
  animation: bg-animation05 0.5s forwards;
  animation-delay: 1.5s;
}

#visibility_addInventory span {
  position: relative;
  overflow: hidden;
}
#visibility_addInventory span:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
}

//From 1-0 to 1-5
// @keyframes bg-animation10 {
//     0% {
//         background: #F9004B;
//         width: 100%;
//     }

//     100% {
//         background: #e9e9e9;
//         width: 0%;
//     }
// }
#visibility_addInventory .visibility-case1-0 span:nth-child(1):before {
  animation: bg-animation10 0.5s forwards;
}

@keyframes bg-animation11 {
  0% {
    background: #41007f;
    width: 100%;
  }

  100% {
    background: #41007f;
    width: 100%;
  }
}
#visibility_addInventory .visibility-case1-1 span:nth-child(1):before {
  animation: bg-animation11 0.5s forwards;
}

@keyframes bg-animation12 {
  0% {
    background: #00d900;
    width: 0%;
  }

  100% {
    // background: linear-gradient(90deg,#f9004b 0%, #ff6c00 100%);
    background: #00d900;
    width: 100%;
  }
}
#visibility_addInventory .visibility-case1-2 span:nth-child(1):before {
  background: #41007f;
  width: 100%;
}
#visibility_addInventory .visibility-case1-2 span:nth-child(2):before {
  animation: bg-animation12 0.5s forwards;
}

@keyframes bg-animation13 {
  0% {
    background: #00d900;
    width: 0%;
  }

  100% {
    background: linear-gradient(90deg, #ff6c00 0%, #ffbd00 100%);
    width: 100%;
  }
}
#visibility_addInventory .visibility-case1-3 span:nth-child(1):before {
  background: #41007f;
  width: 100%;
}
#visibility_addInventory .visibility-case1-3 span:nth-child(2):before {
  animation: bg-animation12 0.5s forwards;
}
#visibility_addInventory .visibility-case1-3 span:nth-child(3):before {
  animation: bg-animation12 0.5s forwards;
  animation-delay: 0.35s;
}

@keyframes bg-animation14 {
  0% {
    background: #f9004b;
    width: 0%;
  }

  100% {
    background: linear-gradient(
      90deg,
      #ffbd00 0%,
      #c1c700 24.14%,
      #00e500 100%
    );
    width: 100%;
  }
}
#visibility_addInventory .visibility-case1-4 span:nth-child(1):before {
  background: #41007f;
  width: 100%;
}
#visibility_addInventory .visibility-case1-4 span:nth-child(2):before {
  animation: bg-animation12 0.5s forwards;
}
#visibility_addInventory .visibility-case1-4 span:nth-child(3):before {
  animation: bg-animation12 0.5s forwards;
  animation-delay: 0.35s;
}
#visibility_addInventory .visibility-case1-4 span:nth-child(4):before {
  animation: bg-animation12 0.5s forwards;
  animation-delay: 0.7s;
}

#visibility_addInventory .visibility-case1-5 span:nth-child(1):before {
  background: #41007f;
  width: 100%;
}
#visibility_addInventory .visibility-case1-5 span:nth-child(2):before {
  animation: bg-animation12 0.5s forwards;
}
#visibility_addInventory .visibility-case1-5 span:nth-child(3):before {
  animation: bg-animation12 0.5s forwards;
  animation-delay: 0.35s;
}
#visibility_addInventory .visibility-case1-5 span:nth-child(4):before {
  animation: bg-animation12 0.5s forwards;
  animation-delay: 0.7s;
}
#visibility_addInventory .visibility-case1-5 span:nth-child(5):before {
  animation: bg-animation12 0.5s forwards;
  animation-delay: 1.1s;
}

#visibility_addInventory .visibility-case2-5 span:nth-child(1):before {
  background: #41007f;
  width: 100%;
}
#visibility_addInventory .visibility-case2-5 span:nth-child(2):before {
  background: #00d900;
}
#visibility_addInventory .visibility-case2-5 span:nth-child(3):before {
  animation: bg-animation12 0.5s forwards;
}
#visibility_addInventory .visibility-case2-5 span:nth-child(4):before {
  animation: bg-animation12 0.5s forwards;
  animation-delay: 0.35s;
}
#visibility_addInventory .visibility-case2-5 span:nth-child(5):before {
  animation: bg-animation12 0.5s forwards;
  animation-delay: 0.7s;
}

#visibility_addInventory .visibility-case3-5 span:nth-child(1):before {
  background: #41007f;
  width: 100%;
}
#visibility_addInventory .visibility-case3-5 span:nth-child(2):before {
  background: #00d900;
  width: 100%;
}
#visibility_addInventory .visibility-case3-5 span:nth-child(3):before {
  background: #00d900;
  width: 100%;
}
#visibility_addInventory .visibility-case3-5 span:nth-child(4):before {
  animation: bg-animation12 0.5s forwards;
}
#visibility_addInventory .visibility-case3-5 span:nth-child(5):before {
  animation: bg-animation12 0.5s forwards;
  animation-delay: 0.35s;
}

#visibility_addInventory .visibility-case4-5 span:nth-child(1):before {
  background: #41007f;
  width: 100%;
}
#visibility_addInventory .visibility-case4-5 span:nth-child(2):before {
  background: #00d900;
  width: 100%;
}
#visibility_addInventory .visibility-case4-5 span:nth-child(3):before {
  background: #00d900;
  width: 100%;
}
#visibility_addInventory .visibility-case4-5 span:nth-child(4):before {
  background: #00d900;
  width: 100%;
}
#visibility_addInventory .visibility-case4-5 span:nth-child(5):before {
  animation: bg-animation12 0.5s forwards;
}

#visibility_addInventory .visibility-case5-5 span:nth-child(1):before {
  background: #41007f;
  width: 100%;
}
#visibility_addInventory .visibility-case5-5 span:nth-child(2):before {
  background: #00d900;
  width: 100%;
}
#visibility_addInventory .visibility-case5-5 span:nth-child(3):before {
  background: #00d900;
  width: 100%;
}
#visibility_addInventory .visibility-case5-5 span:nth-child(4):before {
  background: #00d900;
  width: 100%;
}
#visibility_addInventory .visibility-case5-5 span:nth-child(5):before {
  background: #00d900;
  width: 100%;
}

//From 2-0 to 2-4
@keyframes bg-animation20 {
  0% {
    // background: linear-gradient(90deg, #f9004b 0%, #ff6c00 100%);
    background: #00d900;
    width: 100%;
  }

  100% {
    background: #e9e9e9;
    width: 0%;
  }
}
#visibility_addInventory .visibility-case2-0 span:nth-child(2):before {
  animation: bg-animation20 0.5s backwards;
}
#visibility_addInventory .visibility-case2-0 span:nth-child(1):before {
  animation: bg-animation10 0.5s both;
  animation-delay: 0.35s;
}

#visibility_addInventory .visibility-case2-1 span:nth-child(2):before {
  animation: bg-animation20 0.5s both;
}
#visibility_addInventory .visibility-case2-1 span:nth-child(1):before {
  background: #41007f;
  width: 100%;
}

#visibility_addInventory .visibility-case2-2 span:nth-child(1):before {
  background: #41007f;
  width: 100%;
}
#visibility_addInventory .visibility-case2-2 span:nth-child(2):before {
  //   background: linear-gradient(90deg, #f9004b 0%, #ff6c00 100%);
  background: #00d900;
  width: 100%;
}

@keyframes bg-animation23 {
  0% {
    background: #e9e9e9;
    width: 0%;
  }

  100% {
    // background: linear-gradient(90deg, #ff6c00 0%, #ffbd00 100%);
    background: #00d900;

    width: 100%;
  }
}
#visibility_addInventory .visibility-case2-3 span:nth-child(1):before {
  background: #41007f;
  width: 100%;
}
#visibility_addInventory .visibility-case2-3 span:nth-child(2):before {
  //   background: linear-gradient(90deg, #f9004b 0%, #ff6c00 100%);
  background: #00d900;
  width: 100%;
}
#visibility_addInventory .visibility-case2-3 span:nth-child(3):before {
  animation: bg-animation12 0.5s forwards;
}

#visibility_addInventory .visibility-case2-4 span:nth-child(1):before {
  background: #41007f;
  width: 100%;
}
#visibility_addInventory .visibility-case2-4 span:nth-child(2):before {
  //   background: linear-gradient(90deg, #f9004b 0%, #ff6c00 100%);
  background: #00d900;
  width: 100%;
}
#visibility_addInventory .visibility-case2-4 span:nth-child(3):before {
  animation: bg-animation12 0.5s forwards;
}
#visibility_addInventory .visibility-case2-4 span:nth-child(4):before {
  animation: bg-animation12 0.5s forwards;
  animation-delay: 0.35s;
}

//From 3-0 to 2-4
@keyframes bg-animation30 {
  0% {
    background: linear-gradient(90deg, #ff6c00 0%, #ffbd00 100%);
    width: 100%;
  }

  100% {
    background: #e9e9e9;
    width: 0%;
  }
}
#visibility_addInventory .visibility-case3-0 span:nth-child(3):before {
  animation: bg-animation20 0.5s backwards;
}
#visibility_addInventory .visibility-case3-0 span:nth-child(2):before {
  animation: bg-animation20 0.5s backwards;
  animation-delay: 0.35s;
}
#visibility_addInventory .visibility-case3-0 span:nth-child(1):before {
  animation: bg-animation10 0.5s both;
  animation-delay: 0.7s;
}

#visibility_addInventory .visibility-case3-1 span:nth-child(3):before {
  animation: bg-animation20 0.5s both;
}
#visibility_addInventory .visibility-case3-1 span:nth-child(2):before {
  animation: bg-animation20 0.5s both;
  animation-delay: 0.35s;
}
#visibility_addInventory .visibility-case3-1 span:nth-child(1):before {
  background: #41007f;
  width: 100%;
}

#visibility_addInventory .visibility-case3-2 span:nth-child(3):before {
  animation: bg-animation20 0.5s both;
}
#visibility_addInventory .visibility-case3-2 span:nth-child(2):before {
  //   background: linear-gradient(90deg, #f9004b 0%, #ff6c00 100%);
  background: #00d900;
  width: 100%;
}
#visibility_addInventory .visibility-case3-2 span:nth-child(1):before {
  background: #41007f;
  width: 100%;
}

#visibility_addInventory .visibility-case3-3 span:nth-child(1):before {
  background: #41007f;
  width: 100%;
}
#visibility_addInventory .visibility-case3-3 span:nth-child(2):before {
  //   background: linear-gradient(90deg, #f9004b 0%, #ff6c00 100%);
  background: #00d900;
  width: 100%;
}
#visibility_addInventory .visibility-case3-3 span:nth-child(3):before {
  //   background: linear-gradient(90deg, #ff6c00 0%, #ffbd00 100%);
  background: #00d900;
  width: 100%;
}

#visibility_addInventory .visibility-case3-4 span:nth-child(1):before {
  background: #41007f;
  width: 100%;
}
#visibility_addInventory .visibility-case3-4 span:nth-child(2):before {
  //   background: linear-gradient(90deg, #f9004b 0%, #ff6c00 100%);
  background: #00d900;
  width: 100%;
}
#visibility_addInventory .visibility-case3-4 span:nth-child(3):before {
  //   background: linear-gradient(90deg, #ff6c00 0%, #ffbd00 100%);
  background: #00d900;
  width: 100%;
}
#visibility_addInventory .visibility-case3-4 span:nth-child(4):before {
  animation: bg-animation12 0.5s forwards;
  animation-delay: 0.35s;
}

//From 4-0 to 4-4
@keyframes bg-animation40 {
  0% {
    background: linear-gradient(
      90deg,
      #ffbd00 0%,
      #c1c700 24.14%,
      #00e500 100%
    );
    width: 100%;
  }

  100% {
    background: #e9e9e9;
    width: 0%;
  }
}

#visibility_addInventory .visibility-case4-0 span:nth-child(4):before {
  animation: bg-animation20 0.5s backwards;
}
#visibility_addInventory .visibility-case4-0 span:nth-child(3):before {
  animation: bg-animation20 0.5s backwards;
  animation-delay: 0.35s;
}
#visibility_addInventory .visibility-case4-0 span:nth-child(2):before {
  animation: bg-animation20 0.5s backwards;
  animation-delay: 0.7s;
}
#visibility_addInventory .visibility-case4-0 span:nth-child(1):before {
  animation: bg-animation10 0.5s both;
  animation-delay: 1.1s;
}

#visibility_addInventory .visibility-case4-1 span:nth-child(4):before {
  animation: bg-animation20 0.5s both;
}
#visibility_addInventory .visibility-case4-1 span:nth-child(3):before {
  animation: bg-animation20 0.5s both;
  animation-delay: 0.35s;
}
#visibility_addInventory .visibility-case4-1 span:nth-child(2):before {
  animation: bg-animation20 0.5s both;
  animation-delay: 0.7s;
}
#visibility_addInventory .visibility-case4-1 span:nth-child(1):before {
  background: #41007f;
  width: 100%;
}

#visibility_addInventory .visibility-case4-2 span:nth-child(4):before {
  animation: bg-animation20 0.5s both;
}
#visibility_addInventory .visibility-case4-2 span:nth-child(3):before {
  animation: bg-animation20 0.5s both;
  animation-delay: 0.35s;
}
#visibility_addInventory .visibility-case4-2 span:nth-child(2):before {
  //   background: linear-gradient(90deg, #f9004b 0%, #ff6c00 100%);
  background: #00d900;
  width: 100%;
}
#visibility_addInventory .visibility-case4-2 span:nth-child(1):before {
  background: #41007f;
  width: 100%;
}

#visibility_addInventory .visibility-case4-3 span:nth-child(1):before {
  background: #41007f;
  width: 100%;
}
#visibility_addInventory .visibility-case4-3 span:nth-child(2):before {
  //   background: linear-gradient(90deg, #f9004b 0%, #ff6c00 100%);
  background: #00d900;
  width: 100%;
}
#visibility_addInventory .visibility-case4-3 span:nth-child(3):before {
  //   background: linear-gradient(90deg, #ff6c00 0%, #ffbd00 100%);
  background: #00d900;
  width: 100%;
}
#visibility_addInventory .visibility-case4-3 span:nth-child(4):before {
  animation: bg-animation20 0.5s both;
}

#visibility_addInventory .visibility-case4-4 span:nth-child(1):before {
  background: #41007f;
  width: 100%;
}
#visibility_addInventory .visibility-case4-4 span:nth-child(2):before {
  //   background: linear-gradient(90deg, #f9004b 0%, #ff6c00 100%);
  background: #00d900;
  width: 100%;
}
#visibility_addInventory .visibility-case4-4 span:nth-child(3):before {
  //   background: linear-gradient(90deg, #ff6c00 0%, #ffbd00 100%);
  background: #00d900;
  width: 100%;
}
#visibility_addInventory .visibility-case4-4 span:nth-child(4):before {
  //   background: linear-gradient(90deg, #ffbd00 0%, #c1c700 24.14%, #00e500 100%);
  background: #00d900;
  width: 100%;
}

//From 5-0 to 5-4
@keyframes bg-animation50 {
  0% {
    background: #00e500;
    width: 100%;
  }

  100% {
    background: #e9e9e9;
    width: 0%;
  }
}

#visibility_addInventory .visibility-case5-0 span:nth-child(5):before {
  animation: bg-animation20 0.5s backwards;
}
#visibility_addInventory .visibility-case5-0 span:nth-child(4):before {
  animation: bg-animation20 0.5s backwards;
  animation-delay: 0.35s;
}
#visibility_addInventory .visibility-case5-0 span:nth-child(3):before {
  animation: bg-animation20 0.5s backwards;
  animation-delay: 0.7s;
}
#visibility_addInventory .visibility-case5-0 span:nth-child(2):before {
  animation: bg-animation20 0.5s backwards;
  animation-delay: 1.1s;
}
#visibility_addInventory .visibility-case5-0 span:nth-child(1):before {
  animation: bg-animation10 0.5s both;
  animation-delay: 1.5s;
}

#visibility_addInventory .visibility-case5-1 span:nth-child(5):before {
  animation: bg-animation20 0.5s both;
}
#visibility_addInventory .visibility-case5-1 span:nth-child(4):before {
  animation: bg-animation20 0.5s both;
  animation-delay: 0.35s;
}
#visibility_addInventory .visibility-case5-1 span:nth-child(3):before {
  animation: bg-animation20 0.5s both;
  animation-delay: 0.7s;
}
#visibility_addInventory .visibility-case5-1 span:nth-child(2):before {
  animation: bg-animation20 0.5s both;
  animation-delay: 1.1s;
}
#visibility_addInventory .visibility-case5-1 span:nth-child(1):before {
  background: #41007f;
  width: 100%;
}

#visibility_addInventory .visibility-case5-2 span:nth-child(5):before {
  animation: bg-animation20 0.5s both;
}
#visibility_addInventory .visibility-case5-2 span:nth-child(4):before {
  animation: bg-animation20 0.5s both;
  animation-delay: 0.35s;
}
#visibility_addInventory .visibility-case5-2 span:nth-child(3):before {
  animation: bg-animation20 0.5s both;
  animation-delay: 0.7s;
}
#visibility_addInventory .visibility-case5-2 span:nth-child(2):before {
  //   background: linear-gradient(90deg, #f9004b 0%, #ff6c00 100%);
  background: #00d900;
  width: 100%;
}
#visibility_addInventory .visibility-case5-2 span:nth-child(1):before {
  background: #41007f;
  width: 100%;
}

#visibility_addInventory .visibility-case5-3 span:nth-child(1):before {
  background: #41007f;
  width: 100%;
}
#visibility_addInventory .visibility-case5-3 span:nth-child(2):before {
  //   background: linear-gradient(90deg, #f9004b 0%, #ff6c00 100%);
  background: #00d900;
  width: 100%;
}
#visibility_addInventory .visibility-case5-3 span:nth-child(3):before {
  //   background: linear-gradient(90deg, #ff6c00 0%, #ffbd00 100%);
  background: #00d900;
  width: 100%;
}
#visibility_addInventory .visibility-case5-3 span:nth-child(4):before {
  animation: bg-animation20 0.5s both;
  animation-delay: 0.35s;
}
#visibility_addInventory .visibility-case5-3 span:nth-child(5):before {
  animation: bg-animation20 0.5s both;
}

#visibility_addInventory .visibility-case5-4 span:nth-child(1):before {
  background: #41007f;
  width: 100%;
}
#visibility_addInventory .visibility-case5-4 span:nth-child(2):before {
  //   background: linear-gradient(90deg, #f9004b 0%, #ff6c00 100%);
  background: #00d900;
  width: 100%;
}
#visibility_addInventory .visibility-case5-4 span:nth-child(3):before {
  //   background: linear-gradient(90deg, #ff6c00 0%, #ffbd00 100%);
  background: #00d900;
  width: 100%;
}
#visibility_addInventory .visibility-case5-4 span:nth-child(4):before {
  //   background: linear-gradient(90deg, #ffbd00 0%, #c1c700 24.14%, #00e500 100%);
  background: #00d900;
  width: 100%;
}
#visibility_addInventory .visibility-case5-4 span:nth-child(5):before {
  animation: bg-animation20 0.5s both;
}

@keyframes meter-bg-animation02 {
  0% {
    background: #e9e9e9;
    width: 0;
  }

  100% {
    background: #00d900;
    width: 100%;
  }
}
@keyframes meter-bg-animation03 {
  0% {
    background: #e9e9e9;
    width: 0;
  }

  100% {
    background: #00d900;
    width: 100%;
  }
}
@keyframes meter-bg-animation04 {
  0% {
    background: #e9e9e9;
    width: 0;
  }

  100% {
    background: #00d900;
    width: 100%;
  }
}

@keyframes meter-bg-animation20 {
  0% {
    background: #00d900;
    width: 100%;
  }

  100% {
    background: #e9e9e9;
    width: 0;
  }
}
// @keyframes meter-bg-animation20 {
//     0% {
//         background: linear-gradient(90deg,#f9004b 0%, #ff6c00 100%);
//         width: 100%;
//     }

//     100% {
//         background: #e9e9e9;
//         width: 0;
//     }
// }
#visibility_addInventory
  .table-meter.visibility-case0-1
  span:first-child:before {
  animation: meter-bg-animation02 0.5s forwards;
}
#visibility_addInventory
  .table-meter.visibility-case0-2
  span:first-child:before {
  animation: meter-bg-animation02 0.5s forwards;
}
#visibility_addInventory
  .table-meter.visibility-case0-2
  span:nth-child(2):before {
  animation: meter-bg-animation02 0.5s forwards;
  animation-delay: 0.35s;
}

#visibility_addInventory
  .table-meter.visibility-case0-3
  span:first-child:before {
  animation: meter-bg-animation03 0.5s forwards;
}
#visibility_addInventory
  .table-meter.visibility-case0-3
  span:nth-child(2):before {
  animation: meter-bg-animation03 0.5s forwards;
  animation-delay: 0.35s;
}
#visibility_addInventory
  .table-meter.visibility-case0-3
  span:nth-child(3):before {
  animation: meter-bg-animation03 0.5s forwards;
  animation-delay: 0.7s;
}

#visibility_addInventory
  .table-meter.visibility-case0-4
  span:first-child:before {
  animation: meter-bg-animation04 0.5s forwards;
}
#visibility_addInventory
  .table-meter.visibility-case0-4
  span:nth-child(2):before {
  animation: meter-bg-animation04 0.5s forwards;
  animation-delay: 0.35s;
}
#visibility_addInventory
  .table-meter.visibility-case0-4
  span:nth-child(3):before {
  animation: meter-bg-animation04 0.5s forwards;
  animation-delay: 0.7s;
}
#visibility_addInventory
  .table-meter.visibility-case0-4
  span:nth-child(4):before {
  animation: meter-bg-animation04 0.5s forwards;
  animation-delay: 1.1s;
}

#visibility_addInventory
  .table-meter.visibility-case0-5
  span:first-child:before {
  animation: meter-bg-animation04 0.5s forwards;
}
#visibility_addInventory
  .table-meter.visibility-case0-5
  span:nth-child(2):before {
  animation: meter-bg-animation04 0.5s forwards;
  animation-delay: 0.35s;
}
#visibility_addInventory
  .table-meter.visibility-case0-5
  span:nth-child(3):before {
  animation: meter-bg-animation04 0.5s forwards;
  animation-delay: 0.7s;
}
#visibility_addInventory
  .table-meter.visibility-case0-5
  span:nth-child(4):before {
  animation: meter-bg-animation04 0.5s forwards;
  animation-delay: 1.1s;
}
#visibility_addInventory
  .table-meter.visibility-case0-5
  span:nth-child(5):before {
  animation: meter-bg-animation04 0.5s forwards;
  animation-delay: 1.5s;
}

#visibility_addInventory
  .table-meter.visibility-case1-0
  span:first-child:before {
  animation: meter-bg-animation20 0.5s forwards;
}
#visibility_addInventory
  .table-meter.visibility-case1-1
  span:first-child:before {
  animation: meter-bg-animation02 0.5s forwards;
  // background: #00d900;
}
#visibility_addInventory
  .table-meter.visibility-case1-2
  span:first-child:before {
  // animation: meter-bg-animation02 0.5s forwards;
  background: #00d900;
}
#visibility_addInventory
  .table-meter.visibility-case1-2
  span:nth-child(2):before {
  animation: meter-bg-animation02 0.5s forwards;
  // animation-delay: 0.35s;
}
#visibility_addInventory
  .table-meter.visibility-case1-3
  span:first-child:before {
  // animation: meter-bg-animation02 0.5s forwards;
  background: #00d900;
}
#visibility_addInventory
  .table-meter.visibility-case1-3
  span:nth-child(2):before {
  animation: meter-bg-animation03 0.5s forwards;
  // animation-delay: 0.35s;
}
#visibility_addInventory
  .table-meter.visibility-case1-3
  span:nth-child(3):before {
  animation: meter-bg-animation03 0.5s forwards;
  animation-delay: 0.35s;
}

#visibility_addInventory
  .table-meter.visibility-case1-4
  span:first-child:before {
  // animation: meter-bg-animation02 0.5s forwards;
  background: #00d900;
}
#visibility_addInventory
  .table-meter.visibility-case1-4
  span:nth-child(2):before {
  animation: meter-bg-animation04 0.5s forwards;
  // animation-delay: 0.35s;
}
#visibility_addInventory
  .table-meter.visibility-case1-4
  span:nth-child(3):before {
  animation: meter-bg-animation04 0.5s forwards;
  animation-delay: 0.35s;
}
#visibility_addInventory
  .table-meter.visibility-case1-4
  span:nth-child(4):before {
  animation: meter-bg-animation04 0.5s forwards;
  animation-delay: 0.7s;
}
#visibility_addInventory
  .table-meter.visibility-case1-5
  span:first-child:before {
  // animation: meter-bg-animation02 0.5s forwards;
  background: #00d900;
}
#visibility_addInventory
  .table-meter.visibility-case1-5
  span:nth-child(2):before {
  animation: meter-bg-animation04 0.5s forwards;
  // animation-delay: 0.35s;
}
#visibility_addInventory
  .table-meter.visibility-case1-5
  span:nth-child(3):before {
  animation: meter-bg-animation04 0.5s forwards;
  animation-delay: 0.35s;
}
#visibility_addInventory
  .table-meter.visibility-case1-5
  span:nth-child(4):before {
  animation: meter-bg-animation04 0.5s forwards;
  animation-delay: 0.7s;
}
#visibility_addInventory
  .table-meter.visibility-case1-5
  span:nth-child(5):before {
  animation: meter-bg-animation04 0.5s forwards;
  animation-delay: 1.1s;
}

// #visibility_addInventory .table-meter.visibility-case2-0 span:first-child:before {
//     animation: meter-bg-animation20 0.5s forwards;
//     animation-delay: 0.35s;
// }
// #visibility_addInventory .table-meter.visibility-case2-0 span:nth-child(2):before {
//     animation: meter-bg-animation20 0.5s forwards;
// }

#visibility_addInventory
  .table-meter.visibility-case2-0
  span:nth-child(2):before {
  animation: meter-bg-animation20 0.5s backwards;
}
#visibility_addInventory
  .table-meter.visibility-case2-0
  span:nth-child(1):before {
  animation: meter-bg-animation20 0.5s backwards;
  animation-delay: 0.35s;
}

@keyframes meter-bg-animation21 {
  0% {
    background: #00d900;
    width: 100%;
  }

  100% {
    background: #e9e9e9;
    width: 100%;
  }
}
#visibility_addInventory
  .table-meter.visibility-case2-1
  span:nth-child(2):before {
  animation: meter-bg-animation20 0.5s backwards;
}
#visibility_addInventory
  .table-meter.visibility-case2-1
  span:nth-child(1):before {
  // animation: meter-bg-animation21 0.5s forwards;
  background: #00d900;
}

#visibility_addInventory
  .table-meter.visibility-case2-2
  span:nth-child(2):before {
  background: #00d900;
}
#visibility_addInventory
  .table-meter.visibility-case2-2
  span:nth-child(1):before {
  background: #00d900;
}

#visibility_addInventory
  .table-meter.visibility-case2-3
  span:nth-child(1):before {
  background: #00d900;
}
#visibility_addInventory
  .table-meter.visibility-case2-3
  span:nth-child(2):before {
  background: #00d900;
}
#visibility_addInventory
  .table-meter.visibility-case2-3
  span:nth-child(3):before {
  animation: meter-bg-animation03 0.5s forwards;
  animation-delay: 0.35s;
}

#visibility_addInventory
  .table-meter.visibility-case2-4
  span:nth-child(1):before {
  background: #00d900;
}
#visibility_addInventory
  .table-meter.visibility-case2-4
  span:nth-child(2):before {
  background: #00d900;
}
#visibility_addInventory
  .table-meter.visibility-case2-4
  span:nth-child(3):before {
  animation: meter-bg-animation04 0.5s forwards;
}
#visibility_addInventory
  .table-meter.visibility-case2-4
  span:nth-child(4):before {
  animation: meter-bg-animation04 0.5s forwards;
  animation-delay: 0.35s;
}
#visibility_addInventory
  .table-meter.visibility-case2-5
  span:nth-child(1):before {
  background: #00d900;
}
#visibility_addInventory
  .table-meter.visibility-case2-5
  span:nth-child(2):before {
  background: #00d900;
}
#visibility_addInventory
  .table-meter.visibility-case2-5
  span:nth-child(3):before {
  animation: meter-bg-animation04 0.5s forwards;
}
#visibility_addInventory
  .table-meter.visibility-case2-5
  span:nth-child(4):before {
  animation: meter-bg-animation04 0.5s forwards;
  animation-delay: 0.35s;
}
#visibility_addInventory
  .table-meter.visibility-case2-5
  span:nth-child(5):before {
  animation: meter-bg-animation04 0.5s forwards;
  animation-delay: 0.7s;
}

@keyframes meter-bg-animation31 {
  0% {
    background: #00d900;
    width: 100%;
  }

  100% {
    background: #e9e9e9;
    width: 100%;
  }
}
@keyframes meter-bg-animation30 {
  0% {
    background: #00d900;
    width: 100%;
  }

  100% {
    background: #e9e9e9;
    width: 0%;
  }
}
#visibility_addInventory
  .table-meter.visibility-case3-0
  span:nth-child(1):before {
  animation: meter-bg-animation30 0.5s backwards;
  animation-delay: 0.7s;
}
#visibility_addInventory
  .table-meter.visibility-case3-1
  span:nth-child(3):before {
  animation: meter-bg-animation30 0.5s backwards;
}
#visibility_addInventory
  .table-meter.visibility-case3-1
  span:nth-child(2):before {
  animation: meter-bg-animation30 0.5s backwards;
  animation-delay: 0.35s;
}
#visibility_addInventory
  .table-meter.visibility-case3-1
  span:nth-child(1):before {
  // animation: meter-bg-animation31 0.5s backwards;
  background: #00d900;
  animation-delay: 0.7s;
}

#visibility_addInventory
  .table-meter.visibility-case3-2
  span:nth-child(3):before {
  animation: meter-bg-animation30 0.5s backwards;
}
#visibility_addInventory
  .table-meter.visibility-case3-2
  span:nth-child(2):before {
  background: #00d900;
}
#visibility_addInventory
  .table-meter.visibility-case3-2
  span:nth-child(1):before {
  background: #00d900;
}
#visibility_addInventory
  .table-meter.visibility-case3-3
  span:nth-child(3):before {
  background: #00d900;
}
#visibility_addInventory
  .table-meter.visibility-case3-3
  span:nth-child(2):before {
  background: #00d900;
}
#visibility_addInventory
  .table-meter.visibility-case3-3
  span:nth-child(1):before {
  background: #00d900;
}

#visibility_addInventory
  .table-meter.visibility-case3-4
  span:nth-child(1):before {
  background: #00d900;
}
#visibility_addInventory
  .table-meter.visibility-case3-4
  span:nth-child(2):before {
  background: #00d900;
}
#visibility_addInventory
  .table-meter.visibility-case3-4
  span:nth-child(3):before {
  background: #00d900;
}
#visibility_addInventory
  .table-meter.visibility-case3-4
  span:nth-child(4):before {
  animation: meter-bg-animation04 0.5s forwards;
}

#visibility_addInventory
  .table-meter.visibility-case3-5
  span:nth-child(1):before {
  background: #00d900;
}
#visibility_addInventory
  .table-meter.visibility-case3-5
  span:nth-child(2):before {
  background: #00d900;
}
#visibility_addInventory
  .table-meter.visibility-case3-5
  span:nth-child(3):before {
  background: #00d900;
}
#visibility_addInventory
  .table-meter.visibility-case3-5
  span:nth-child(4):before {
  animation: meter-bg-animation04 0.5s forwards;
}
#visibility_addInventory
  .table-meter.visibility-case3-5
  span:nth-child(5):before {
  animation: meter-bg-animation04 0.5s forwards;
  animation-delay: 0.35s;
}

@keyframes meter-bg-animation41 {
  0% {
    background: #00d900;
    width: 100%;
  }

  100% {
    background: #e9e9e9;
    width: 0%;
  }
}
@keyframes meter-bg-animation42 {
  0% {
    background: #00d900;
    width: 100%;
  }

  100% {
    background: #e9e9e9;
    width: 0%;
  }
}
@keyframes meter-bg-animation43 {
  0% {
    background: #00d900;
    width: 100%;
  }

  100% {
    background: #e9e9e9;
    width: 0%;
  }
}

@keyframes meter-bg-animation40 {
  0% {
    background: #00d900;
    width: 100%;
  }

  100% {
    background: #e9e9e9;
    width: 0%;
  }
}
#visibility_addInventory
  .table-meter.visibility-case4-0
  span:nth-child(1):before {
  animation: meter-bg-animation41 0.5s backwards;
  animation-delay: 1.1s;
}
#visibility_addInventory
  .table-meter.visibility-case4-1
  span:nth-child(4):before {
  animation: meter-bg-animation41 0.5s backwards;
}
#visibility_addInventory
  .table-meter.visibility-case4-1
  span:nth-child(3):before {
  animation: meter-bg-animation41 0.5s backwards;
  animation-delay: 0.35s;
}
#visibility_addInventory
  .table-meter.visibility-case4-1
  span:nth-child(2):before {
  animation: meter-bg-animation41 0.5s backwards;
  animation-delay: 0.7s;
}
#visibility_addInventory
  .table-meter.visibility-case4-1
  span:nth-child(1):before {
  // animation: meter-bg-animation41 0.5s forwards;
  background: #00d900;
}

#visibility_addInventory
  .table-meter.visibility-case4-2
  span:nth-child(4):before {
  animation: meter-bg-animation40 0.5s backwards;
}
#visibility_addInventory
  .table-meter.visibility-case4-2
  span:nth-child(3):before {
  animation: meter-bg-animation40 0.5s backwards;
  animation-delay: 0.35s;
}
#visibility_addInventory
  .table-meter.visibility-case4-2
  span:nth-child(2):before {
  // animation: meter-bg-animation42 0.5s forwards;
  background: #00d900;
}
#visibility_addInventory
  .table-meter.visibility-case4-2
  span:nth-child(1):before {
  // animation: meter-bg-animation42 0.5s forwards;
  background: #00d900;
}

#visibility_addInventory
  .table-meter.visibility-case4-3
  span:nth-child(4):before {
  animation: meter-bg-animation40 0.5s backwards;
}
#visibility_addInventory
  .table-meter.visibility-case4-3
  span:nth-child(3):before {
  // animation: meter-bg-animation43 0.5s forwards;
  background: #00d900;
}
#visibility_addInventory
  .table-meter.visibility-case4-3
  span:nth-child(2):before {
  // animation: meter-bg-animation43 0.5s forwards;
  background: #00d900;
}
#visibility_addInventory
  .table-meter.visibility-case4-3
  span:nth-child(1):before {
  // animation: meter-bg-animation43 0.5s forwards;
  background: #00d900;
}

#visibility_addInventory
  .table-meter.visibility-case4-4
  span:nth-child(4):before {
  background: #00d900;
}
#visibility_addInventory
  .table-meter.visibility-case4-4
  span:nth-child(3):before {
  background: #00d900;
}
#visibility_addInventory
  .table-meter.visibility-case4-4
  span:nth-child(2):before {
  background: #00d900;
}
#visibility_addInventory
  .table-meter.visibility-case4-4
  span:nth-child(1):before {
  background: #00d900;
}

#visibility_addInventory
  .table-meter.visibility-case5-1
  span:nth-child(5):before {
  animation: meter-bg-animation40 0.5s backwards;
}
#visibility_addInventory
  .table-meter.visibility-case5-1
  span:nth-child(4):before {
  animation: meter-bg-animation40 0.5s backwards;
  animation-delay: 0.35s;
}
#visibility_addInventory
  .table-meter.visibility-case5-1
  span:nth-child(3):before {
  animation: meter-bg-animation40 0.5s backwards;
  animation-delay: 0.7s;
}
#visibility_addInventory
  .table-meter.visibility-case5-1
  span:nth-child(2):before {
  animation: meter-bg-animation40 0.5s backwards;
  animation-delay: 1.1s;
}
#visibility_addInventory
  .table-meter.visibility-case5-1
  span:nth-child(1):before {
  // animation: meter-bg-animation41 0.5s forwards;
  background: #00d900;
}

#visibility_addInventory
  .table-meter.visibility-case5-2
  span:nth-child(5):before {
  animation: meter-bg-animation40 0.5s backwards;
}
#visibility_addInventory
  .table-meter.visibility-case5-2
  span:nth-child(4):before {
  animation: meter-bg-animation40 0.5s backwards;
  animation-delay: 0.35s;
}
#visibility_addInventory
  .table-meter.visibility-case5-2
  span:nth-child(3):before {
  animation: meter-bg-animation40 0.5s backwards;
  animation-delay: 0.7s;
}
#visibility_addInventory
  .table-meter.visibility-case5-2
  span:nth-child(2):before {
  // animation: meter-bg-animation42 0.5s forwards;
  background: #00d900;
}
#visibility_addInventory
  .table-meter.visibility-case5-2
  span:nth-child(1):before {
  // animation: meter-bg-animation42 0.5s forwards;
  background: #00d900;
}

#visibility_addInventory
  .table-meter.visibility-case5-3
  span:nth-child(5):before {
  animation: meter-bg-animation40 0.5s backwards;
}
#visibility_addInventory
  .table-meter.visibility-case5-3
  span:nth-child(4):before {
  animation: meter-bg-animation40 0.5s backwards;
  animation-delay: 0.35s;
}
#visibility_addInventory
  .table-meter.visibility-case5-3
  span:nth-child(3):before {
  // animation: meter-bg-animation43 0.5s forwards;
  background: #00d900;
}
#visibility_addInventory
  .table-meter.visibility-case5-3
  span:nth-child(2):before {
  // animation: meter-bg-animation43 0.5s forwards;
  background: #00d900;
}
#visibility_addInventory
  .table-meter.visibility-case5-3
  span:nth-child(1):before {
  // animation: meter-bg-animation43 0.5s forwards;
  background: #00d900;
}

#visibility_addInventory
  .table-meter.visibility-case5-4
  span:nth-child(5):before {
  animation: meter-bg-animation40 0.5s backwards;
}
#visibility_addInventory
  .table-meter.visibility-case5-4
  span:nth-child(4):before {
  background: #00d900;
}
#visibility_addInventory
  .table-meter.visibility-case5-4
  span:nth-child(3):before {
  background: #00d900;
}
#visibility_addInventory
  .table-meter.visibility-case5-4
  span:nth-child(2):before {
  background: #00d900;
}
#visibility_addInventory
  .table-meter.visibility-case5-4
  span:nth-child(1):before {
  background: #00d900;
}

#visibility_addInventory {
  .svg-wrap {
    &.price_high {
      span:not(:nth-child(1)) {
        &:before {
          @apply bg-rose-500 #{!important};
        }
      }
    }
    &.price_medium {
      span:not(:nth-child(1)) {
        &:before {
          @apply bg-orange-500 #{!important};
        }
      }
    }
    &.price_regular {
      span:not(:nth-child(1)) {
        &:before {
          @apply bg-green-600 #{!important};
        }
      }
    }
    &.price_low {
      span:not(:nth-child(1)) {
        &:before {
          @apply bg-sky-blue-500 #{!important};
        }
      }
    }
    &.price_with_empty {
      span:not(:nth-child(1)) {
        &:before {
          @apply bg-indigo-500 #{!important};
        }
      }
    }
  }
  .table-meter {
    &.price_high {
      span {
        &:before {
          @apply bg-rose-500 #{!important};
        }
      }
    }
    &.price_medium {
      span {
        &:before {
          @apply bg-orange-500 #{!important};
        }
      }
    }
    &.price_regular {
      span {
        &:before {
          @apply bg-green-600 #{!important};
        }
      }
    }
    &.price_low {
      span {
        &:before {
          @apply bg-sky-blue-500 #{!important};
        }
      }
    }
    &.price_with_empty {
      span {
        &:before {
          @apply bg-indigo-500 #{!important};
        }
      }
    }
  }
}
