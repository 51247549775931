/* form global style */
[type="text"],
[type="email"],
[type="url"],
[type="password"],
[type="number"],
[type="date"],
[type="datetime-local"],
[type="month"],
[type="search"],
[type="tel"],
[type="time"],
[type="week"],
[type="radio"],
[multiple],
textarea,
select {
  &:focus {
    box-shadow: none !important;
  }
}

input[type="checkbox"] {
  --tw-ring-offset-width: 0 !important;

  &:checked {
    // border-color: rgba(0,0,0,.15);
    @apply bg-checktick bg-[length:0.5rem] #{!important};
    .whiteFormFields & {
      @apply bg-[length:.625rem] #{!important};
    }
  }

  &:not(:checked) {
    &:hover {
      @apply border-indigo-500;
      background-color: rgba(111, 108, 255, 0.07);
    }

    //@apply hover:border-indigo-500 hover:bg-indigo-500 hover:bg-opacity-[7%];
  }
  &.green-check {
    &:checked {
      // border-color: rgba(0,0,0,.15);
      @apply bg-checktick bg-[length:0.6rem] #{!important};
    }
  }
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

select {
  background-image: url("../media/icons/standard_icons/down-angle.svg") !important;
  background-size: 0.375rem !important;
  background-position: right 0.625rem center !important;

  &:not([size]) {
    @apply pr-6 #{!important};
  }

  &:hover,
  &:focus {
    background-image: url("../media/icons/standard_icons/down-angle-hover.svg") !important;
  }
}

.menu-search-input {
  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    @apply hidden;
  }
}

/* all dropdown */
// For the sign up country code
.country-code {
  .all_dropdown {
    &__control {
      .whiteFormFields & {
        min-width: 6.0625rem;
        border: 0 solid !important;
        @apply border-r rounded-r-none border-r-gray-200  #{!important};
      }
      &.all_dropdown__control--is-focused {
        .whiteFormFields & {
          @apply border-r border-r-gray-200 #{!important};
        }
      }
    }
  }
  .dropdown:hover,
  .focused {
    .all_dropdown {
      &__control {
        .whiteFormFields & {
          min-width: 6.0625rem;
          border: 0 solid !important;
          @apply border-r rounded-r-none border-r-indigo-500 #{!important};
        }
        &.all_dropdown__control--is-focused {
          .whiteFormFields & {
            @apply border-r border-r-indigo-500 #{!important};
          }
        }
      }
    }
  }
  .not-valid {
    .all_dropdown {
      &__control {
        .whiteFormFields & {
          min-width: 6.0625rem;
          border: 0 solid !important;
          @apply border-r rounded-r-none border-r-rose-500  #{!important};
        }
      }
    }
  }
}
.all_dropdown {
  &__menu-portal {
    @apply z-[11] #{!important};
  }
  &__value-container {
    padding-left: 0.5625rem !important;
    pointer-events: none;
    width: calc(100% - 1.875rem);
    .all_dropdown__input-container {
      overflow: hidden;
    }
    .whiteFormFields & {
      padding-left: 0.9375rem !important;
    }
  }

  &__single-value {
    @apply row-start-1 row-end-2 col-start-1 col-end-2 #{!important};
    > div {
      @apply text-ellipsis overflow-hidden whitespace-nowrap;
    }
  }

  &__control {
    border-width: 1px !important;
    height: 1.875rem !important;
    @apply text-xs;
    @apply border-gray-300 #{!important};
    .whiteFormFields & {
      height: 2.5rem !important;
      @apply border-violet-800 #{!important};
    }
    .smallDropdown & {
      height: 1.875rem !important;
    }
    .subscription_drop & {
      height: 1.5rem !important;
    }

    &--menu-is-open {
      @apply border-indigo-500 #{!important};
      &.all_dropdown__control--is-focused {
        .whiteFormFields & {
          @apply border-indigo-500 #{!important};
          .all_dropdown__indicator {
            transform: scale(-1) !important;
            background-position: right center;
          }
        }
        .all_dropdown__value-container {
          .all_dropdown__placeholder {
            @apply text-indigo-500 #{!important};
          }
        }
      }
      .all_dropdown__placeholder {
        .whiteFormFields & {
          font-size: 0.6875rem !important;
          top: -1.125rem;
          background-color: #fff;
          transition: 0.3s;
        }
      }
    }
    &:not(.all_dropdown__control--menu-is-open) {
      &:hover {
        border-color: #6f6cff !important;
        .all_dropdown__placeholder {
          @apply text-indigo-500;
        }
        .all_dropdown__indicator {
          background-image: url("../media/icons/standard_icons/select-angle-indigo.svg") !important;
          .whiteFormFields & {
            background-image: url("../media/icons/standard_icons/down-angle-hover1.svg") !important;
          }
        }
      }
    }
    &--is-focused {
      &:not(.all_dropdown__control--menu-is-open) {
        .all_dropdown__indicator {
          .whiteFormFields & {
            filter: brightness(0);
          }
        }
      }
    }
  }

  &__placeholder {
    padding: 0 0.1875rem !important;
    left: -0.1875rem !important;
    @apply whitespace-nowrap text-ellipsis overflow-hidden;
    @apply m-0 #{!important};
  }

  &__placeholder,
  &__single-value {
    @apply text-xs font-medium ml-0 #{!important};
    .whiteFormFields & {
      font-size: 0.875rem !important;
      font-weight: 400 !important;
      @apply text-violet-800;
    }
  }

  &__value-container {
    &.all_dropdown__value-container--has-value {
      .all_dropdown__placeholder {
        font-size: 0.625rem !important;
        @apply text-gray-400;
        .whiteFormFields & {
          font-size: 0.6875rem !important;
          top: -1.125rem;
          transition: 0.3s;
          @apply text-indigo-500;
        }
      }
    }
  }

  &__clear-indicator {
    @apply hidden #{!important};
  }

  &__indicator svg {
    @apply hidden;
  }

  &__indicator {
    background-repeat: no-repeat;
    transform: none !important;
    width: 1.125rem;
    height: 0.312rem;
    // height: 0.25rem;
    padding: 0 !important;
    background-image: url("../media/icons/standard_icons/down-angle.svg") !important;
    background-size: 0.375rem !important;
    .txTradeBuySideBarQuantity & {
      background-image: url("../media/icons/standard_icons/down-angle-violet.svg") !important;
    }
    .whiteFormFields & {
      background-size: 0.75rem 0.3125rem !important;
      background-image: url("../media/icons/standard_icons/down-angle1.svg") !important;
      width: 1.6875rem;
    }
  }

  &__control--menu-is-open {
    .all_dropdown__indicator {
      background-image: url("../media/icons/standard_icons/up-angle.svg") !important;
      .whiteFormFields & {
        background-image: url("../media/icons/standard_icons/down-angle-hover1.svg") !important;
      }
    }
  }

  &__menu {
    margin-top: 0.312rem !important;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.1) !important;
    z-index: 25 !important;
    @apply capitalize overflow-hidden;

    &-notice--no-options {
      @apply text-sm12 text-left px-3 py-1 #{!important};
    }
  }
  &__menu-list {
    max-height: 16.25rem !important;
  }

  &__option {
    position: relative;
    padding: 0.437rem 0.625rem !important;
    @apply text-xs font-medium transition;
    @apply text-xs #{!important};
    &.actcross {
      @apply pr-7 #{!important};
    }
  }

  &__indicator-separator {
    @apply hidden;
  }
}

.table-head-select {
  background-image: url("../media/icons/standard_icons/down-angle.svg") !important;
  background-repeat: no-repeat;
  background-size: 0.375rem !important;
  background-position: right 0.312rem center !important;
}

.accordion-item {
  select,
  .ba_item .sel_item {
    background-image: url("../media/icons/standard_icons/event-select-angle.svg") !important;
    background-position: right 0.5rem center !important;
  }

  .ba_item {
    @apply cursor-pointer;
    .sel_item {
      @apply bg-no-repeat;
    }
    &:hover {
      .sel_item {
        @apply text-indigo-500;
      }
    }
  }

  .ba_item:hover .sel_item {
    background-image: url("../media/icons/standard_icons/select-angle-indigo.svg") !important;
  }
  .ba_item .sel_item[aria-expanded="true"] {
    background-image: url("../media/icons/standard_icons/selectindigoRotate.svg") !important;
  }
}

/* Inventory Dropdown - searching field */
.inventory_select {
  &__indicator-separator {
    @apply hidden;
  }

  &__group {
    @apply py-0 #{!important};
  }

  &__control {
    display: flex !important;
    flex-wrap: wrap !important;
    flex-direction: row !important;
    padding-left: 0.625rem;
    border-width: 1px !important;
    font-size: 0.9375rem;
    // height: auto !important;
    height: 1.875rem !important;
    cursor: pointer !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    background-color: rgba(121, 110, 255, 0.07) !important;
    border-color: rgba(111, 108, 255, 0.2) !important;

    &:hover,
    &:focus,
    &--is-focused {
      // background-color: rgba(111, 108, 255, 0.07) !important;
      @apply border-indigo-500 #{!important};
    }
  }

  &__input-container {
    margin: 0 !important;
    padding-bottom: 0 !important;
    padding-top: 0px !important;
    overflow: hidden;

    input {
      // @apply text-violet-800 #{!important};
    }
  }

  &__placeholder {
    @apply whitespace-nowrap text-ellipsis overflow-hidden;
  }

  &__indicator {
    @apply hidden #{!important};
  }

  &__menu-list {
    padding: 0.75rem 0.75rem !important;
  }

  &__menu-portal {
    z-index: 25 !important;
  }
  &__value-container {
    overflow: hidden !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
}

.searchbar-wrap {
  @apply bg-white transition-all w-full;
  &.menu_open {
    @media (min-width: 768px) {
      width: 150%;
      padding-right: 10px;
      &:before {
        content: "";
        position: absolute;
        left: calc(100% + 0px);
        top: 0;
        width: 40px;
        height: 100%;
        background: linear-gradient(90deg, white, transparent);
      }
    }
  }
  .inventory_select {
    &__menu {
      @apply z-30;
    }

    &__single-value {
      @apply row-start-1 row-end-2 col-start-1 col-end-2 text-violet-800;
    }
  }
  &.cloneDisabled {
    .inventory_select {
      &__control {
        @apply bg-gray-100 border-none pointer-events-none #{!important};
        .search_btn {
          svg {
            @apply fill-gray-400;
            path,
            g {
              @apply stroke-gray-400;
            }
          }
        }
        .cross_btn {
          svg {
            @apply stroke-gray-400;
          }
        }
      }
      &__placeholder {
        @apply text-gray-400;
      }
      &__single-value {
        @apply text-gray-400;
      }
    }
  }
}
.accordion-search {
  .searchbar-wrap {
    &.menu_open {
      width: 100%;
      padding-right: 0px;
      @media (min-width: 768px) {
        &:before {
          display: none;
        }
      }
    }
  }
}

/* form_multiDropDown */
.form_multiDropDown {
  &__menu-portal {
    @apply z-10;
  }
  &__menu {
    min-width: 10.375rem;
  }

  &__menu-list {
    @apply scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full;
  }
  &__value-container {
    padding-left: 0.5625rem !important;
  }
  &__placeholder {
    margin-left: 0 !important;
  }
}

.search__item {
  @apply cursor-pointer;

  &:hover,
  &:hover > span,
  &:hover > span:first-child,
  &:hover > span svg,
  &:hover > span path {
    @apply border-indigo-500/20 bg-indigo-500/5;
  }
  // &.events_listt {
  //   &:hover {
  //     @apply border-indigo-500 #{!important};
  //     background-color: rgb(111 108 255 / 0.07) !important;
  //     span {
  //       @apply border-indigo-500/20 #{!important};
  //     }
  //   }
  // }
}
.search__item.selected,
.search__item.selected:hover {
  @apply cursor-pointer border-indigo-500 bg-indigo-500/5 #{!important};

  > span,
  > span:first-child,
  > span svg,
  > span path {
    @apply text-violet-800 fill-violet-800 border-indigo-500/20 bg-indigo-500/5;
  }
}

.search__item.checked {
  @apply bg-indigo-500/5;

  span,
  span:first-child,
  span svg,
  span path {
    @apply text-indigo-500 fill-indigo-500 border-indigo-500/20;
  }
}

.marketplace-table {
  border-collapse: separate;
  border-spacing: 0;
  @apply text-xs;
}

.form_multiDropDown__menu-notice {
  @apply text-xs capitalize;
}

/* inventory-table */
.inventory-table:not(.sales-table) {
  .dropdown {
    .dropdown-toggle,
    input {
      min-height: 1.5rem;
      height: 1.5rem;
    }

    input {
      min-width: 5rem;
      // max-width: 6.875rem;
      max-width: 5rem;
    }

    label {
      @apply hidden;
    }
  }

  tbody tr {
    &:nth-last-child(1),
    &:nth-last-child(2) {
      &.border-b {
        &:not(&:has(+ .show)) {
          border-bottom: none;
        }
      }
    }
  }

  div.-selected {
    ~ div.-selected {
      input:not([type="checkbox"]),
      .price_field,
      .table_multiDropdown__control,
      .table_dropdown__control,
      .in-hand-check,
      .in-hand-check {
        @apply bg-gray-100 text-violet-800 border-none;
      }

      .in-hand-check input {
        @apply bg-gray-200 border-none;

        &[type="checkbox"]:checked {
          @apply bg-indigo-500 border-indigo-500;
        }
      }

      input::placeholder,
      .table_multiDropdown__control .table_multiDropdown__placeholder,
      .table_dropdown__control .table_dropdown__placeholder,
      .row-disabled .price_field {
        @apply text-violet-800;
      }

      .table_multiDropdown__indicators,
      .table_dropdown__indicators {
        @apply hidden;
      }

      .price_field,
      .table-date-icon {
        border-right: 1px solid;
        @apply border-white #{!important};
      }

      .table-date-icon {
        @apply fill-violet-800;
        z-index: 1;
      }
    }
  }

  .false .flex + .accordion-collapse {
    border-radius: 0 !important;
  }
  .-selected:not(.first).first_row {
    input:not([type="checkbox"]),
    .price_field,
    .table_multiDropdown__control,
    .table_dropdown__control,
    .in-hand-check,
    .in-hand-check {
      @apply bg-gray-100 text-violet-800 border-none;
    }

    .in-hand-check input {
      @apply bg-gray-200 border-none;

      &[type="checkbox"]:checked {
        @apply bg-indigo-500 border-indigo-500;
      }
    }
    input::placeholder,
    .table_multiDropdown__control .table_multiDropdown__placeholder,
    .table_dropdown__control .table_dropdown__placeholder,
    .row-disabled .price_field {
      @apply text-violet-800;
    }
    .table_multiDropdown__indicators,
    .table_dropdown__indicators {
      @apply hidden;
    }
    .price_field,
    .table-date-icon {
      border-right: 1px solid;
      @apply border-white #{!important};
    }
    .table-date-icon {
      @apply fill-violet-800;
      z-index: 1;
    }
  }
}

.sales-table {
  tbody tr {
    &:nth-last-child(1) {
      &.border-b {
        &:not(&:has(+ .show)) {
          border-bottom: none;
        }
      }
    }
  }
}

.price_exchange_btn {
  &:not(.collapsed) {
    @apply bg-indigo-500 border-indigo-500;

    > span {
      @apply fill-gray-100;
    }
  }
}

.css-t3ipsp-control {
  box-shadow: none !important;
  @apply border-indigo-500;
}

/* multi-select-dropdown */
.multi-select-dropdown {
  .table_multiDropdown__control {
    height: 1.5rem !important;
  }

  .css-13cymwt-control,
  .css-t3ipsp-control {
    min-height: auto;
    height: 1.875rem;
    @apply border-gray-300;
  }

  .form_multiDropDown {
    &__control {
      border-width: 1px !important;

      box-shadow: none;
      @apply text-xs;
      @apply h-[1.875rem] border-gray-300 #{!important};

      &--menu-is-open {
        @apply border-indigo-500 #{!important};

        .form_multiDropDown__dropdown-indicator {
          background-image: url("../media/icons/standard_icons/up-angle.svg") !important;
        }

        .form_multiDropDown__placeholder {
          @apply text-indigo-500 #{!important};
        }
      }

      &:hover {
        border-color: #6f6cff !important;
        .form_multiDropDown__placeholder {
          @apply text-indigo-500;
        }
      }
      &:not(.form_multiDropDown__control--menu-is-open):hover {
        .form_multiDropDown__dropdown-indicator {
          background-image: url("../media/icons/standard_icons/select-angle-indigo.svg") !important;
        }
      }
    }

    &__input-container {
      @apply text-xs font-medium m-0 p-0 overflow-hidden;
    }

    &__placeholder,
    &__input-container {
      @apply text-xs font-medium;
    }

    &__indicator-separator {
      @apply hidden #{!important};
    }

    &__dropdown-indicator {
      background-repeat: no-repeat;
      background-size: 0.375rem !important;
      background-position: center !important;
      width: 1.875rem;
      height: 0.312rem;
      background-image: url("../media/icons/standard_icons/down-angle.svg") !important;

      svg {
        @apply hidden #{!important};
      }
    }

    &__clear-indicator {
      padding: 0 !important;
      height: 0.875rem;
      width: 0.875rem;
      @apply absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex items-center justify-center cursor-pointer bg-white rounded-full;
    }

    &__indicator {
      svg {
        @apply fill-violet-800;

        &:hover {
          @apply fill-indigo-500;
        }
      }
    }

    &__indicators {
      position: relative;
    }

    &__menu-list {
      max-height: 16.25rem;
      .form_multiDropDown__option {
        &:hover {
          @apply bg-indigo-500/10;

          input[type="checkbox"] {
            @apply border-indigo-500;
          }
        }
      }
    }

    &__value-container {
      @apply overflow-visible;
      width: calc(100% - 1.875rem);
    }

    &__placeholder {
      @apply absolute overflow-hidden whitespace-nowrap top-0 transition;
    }

    &__value-container--has-value {
      .form_multiDropDown__placeholder {
        font-size: 0.625rem;
        @apply -top-3 left-1.5 text-gray-400 text-xxs bg-white px-[.1875rem] m-0 rounded-full;
      }
    }

    &__menu {
      @apply z-20;
    }
  }

  .form_multiDropDown__menu-list > div,
  .form_multiDropDown__value-container--has-value {
    @apply font-medium bg-white;
    @apply text-xs #{!important};
  }

  input[type="checkbox"] {
    height: 0.812rem;
    width: 0.812rem;
    border-radius: 3px;
    transition: 0.3s;
    @apply bg-gray-100 border-gray-300;

    &:checked {
      @apply bg-indigo-500;
    }
  }

  .css-1nmdiq5-menu {
    box-shadow: 0 0.0625rem 0.25rem rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }
}

/* set all dropdown content after placement change */
.form_multiDropDown__option,
.table_multiDropdown__option,
.table_dropdown__option {
  @apply font-medium text-violet-800;
  @apply text-xs #{!important};
}

// .form_multiDropDown__option:hover, .table_multiDropdown__option:hover, .table_dropdown__option:hover, .css-d7l1ni-option,
// // .table_dropdown__option--is-focused, .table_dropdown__option--is-selected {
// //   @apply text-violet-800  #{!important};
// // }
// .table_dropdown__option--is-selected {
//   @apply text-indigo-500;
// }
.table_dropdown__clear-indicator {
  @apply hidden #{!important};
}

.table_dropdown__option {
  @apply relative;

  &.actcross {
    @apply pr-7;
  }
}

.form_multiDropDown__option,
.table_multiDropdown__option {
  input[type="checkbox"] {
    margin-left: 0.312rem !important;
    border-radius: 3px;
    height: 0.812rem;
    width: 0.812rem;
    transition: 0.3s;
    @apply bg-gray-100 border-gray-300;

    &:checked {
      @apply border-indigo-500;
      @apply bg-indigo-500 #{!important};
    }
  }

  &:hover {
    background-color: #f5f5fe !important;
    @apply text-indigo-500 #{!important};
    input[type="checkbox"] {
      @apply border-indigo-500 bg-white;
    }
  }
}

/* inventory table selectbox */
.table_multiDropdown {
  &__dropdown-indicator,
  &__indicator-separator {
    @apply hidden #{!important};
  }

  &__clear-indicator {
    padding: 0 !important;
    height: 0.875rem;
    width: 0.875rem;
    @apply flex items-center justify-center cursor-pointer bg-white rounded-full;

    svg {
      @apply fill-violet-800;
      margin-right: 2px;
    }
  }

  &__indicators {
    background-image: url("../media/icons/standard_icons/down-angle.svg") !important;
    background-repeat: no-repeat;
    transform: none !important;
    width: 1rem;
    height: 0.25rem;
    margin-top: 0.625rem;
    padding: 0 !important;
  }

  &__control--menu-is-open {
    .table_multiDropdown__indicators {
      background-image: url("../media/icons/standard_icons/up-angle.svg") !important;
    }
  }

  &__value-container {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    padding-left: 10px !important;
  }
  &__placeholder {
    margin-left: 0 !important;
  }
}
tr,
.table_row {
  .table_dropdown,
  .table_multiDropdown {
    &__control {
      border-color: #d9d9d9 !important;
      &:hover {
        border-color: #6f6cff !important;
        .table_multiDropdown__placeholder,
        .table_dropdown__placeholder {
          @apply text-indigo-500;
        }
      }
      &:not(
          .table_multiDropdown__control--menu-is-open,
          .table_dropdown__control--menu-is-open
        ) {
        &:hover {
          .table_multiDropdown__indicators,
          .table_dropdown__indicator {
            background-image: url("../media/icons/standard_icons/select-angle-indigo.svg") !important;
          }
        }
      }
    }
  }
  .table_dropdown {
    &__value-container {
      padding: 0 0.5rem !important;
    }
  }
}
.table_dropdown,
.table_multiDropdown {
  &__control {
    min-height: 1.5rem !important;

    &--is-focused {
      @apply border-indigo-500 #{!important};
    }
  }

  &__input-container,
  &__value-container {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  &__dropdown-indicator {
    svg {
      @apply hidden;
    }
  }

  &__dropdown-indicator {
    background-image: url("../media/icons/standard_icons/down-angle.svg") !important;
    background-repeat: no-repeat;
    background-size: 0.375rem;
    transform: none !important;
    width: 1.125rem;
    height: 0.25rem;
    padding: 0 !important;
  }

  &__control--menu-is-open {
    .table_dropdown__dropdown-indicator {
      background-image: url("../media/icons/standard_icons/up-angle.svg") !important;
    }
  }
  &__control--is-disabled {
    @apply border-none bg-gray-100 #{!important};
    .table_dropdown__placeholder,
    .table_multiDropdown__placeholder {
      @apply text-gray-400;
    }
  }

  &__indicator-separator {
    @apply hidden #{!important};
  }

  &__menu-notice {
    @apply text-xs #{!important};
  }
}

/* price field */
.price_field {
  &.show {
    @apply bg-indigo-500 border-indigo-500;
    @apply text-white #{!important};

    + ul + div {
      input {
        @apply border-indigo-500 #{!important};
      }

      label {
        @apply text-indigo-500 #{!important};
      }
    }
  }
}

.error-field {
  .price_field {
    @apply border-rose-500 #{!important};

    &.show {
      @apply border-indigo-500 #{!important};

      + ul + div {
        input {
          @apply border-rose-500 #{!important};
        }
      }
    }
  }

  input,
  input:disabled {
    @apply border-rose-500 rounded-l-none #{!important};

    &::placeholder {
      @apply text-rose-500 #{!important};
    }
  }
}
input:disabled,
textarea:disabled {
  opacity: 1 !important;
}
.attendee_dob {
  .error-field {
    input {
      @apply border-rose-500 #{!important};

      &::placeholder {
        @apply text-gray-500 #{!important};
      }
    }
  }
  .dateplaceholder {
    display: none;
  }
}

/* table-head-accordion */
.table-head-acc {
  .dropdown {
    input {
      @apply h-6;
    }

    .price_field {
      min-width: 1.5rem;
      min-height: 1.5rem;
      @apply py-0;
    }

    &-menu {
      li {
        min-width: 1.5rem;
        @apply px-1;
      }
    }
  }
}

.tableSearchField {
  &__control {
    display: flex;
    flex-direction: row !important;
  }

  &__indicators {
    @apply hidden #{!important};
  }

  &__control {
    height: 30px !important;
    @apply border-none #{!important};
  }

  &__menu-list {
    padding-left: 0.8125rem !important;
    padding-top: 0.8125rem !important;
    @apply text-black;
  }
}

/****Drop down 180px*****/
.dd-180 {
  .all_dropdown__control,
  .css-jnfved-control {
    @screen lg {
      max-width: 11.25rem;
      min-width: 11.25rem;
      @apply w-full;
    }
  }
}

/* searchable-multi-select-dropdown */
.searchable_form_multiDropDown {
  &__control {
    min-height: auto !important;
    @apply shadow-none text-xs h-8 border;
    @apply border-gray-300 #{!important};

    &--menu-is-open {
      @apply bg-indigo-500/10 border-indigo-500 #{!important};

      .searchable_form_multiDropDown__dropdown-indicator {
        background-image: url("../media/icons/standard_icons/up-angle.svg") !important;
      }

      .searchable_form_multiDropDown__placeholder {
        @apply text-violet-800;
      }
    }
  }

  &__input-container {
    @apply text-xs font-medium m-0 p-0;
  }

  &__placeholder,
  &__input-container {
    @apply text-xs font-medium;
  }

  &__indicator-separator {
    @apply hidden #{!important};
  }

  &__dropdown-indicator {
    background-repeat: no-repeat;
    background-size: 0.375rem !important;
    background-position: center !important;
    width: 1.875rem;
    height: 0.312rem;
    background-image: url("../media/icons/standard_icons/down-angle.svg") !important;

    svg {
      @apply hidden #{!important};
    }
  }

  &__clear-indicator {
    padding: 0 !important;
    height: 0.875rem;
    width: 0.875rem;
    @apply absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex items-center justify-center cursor-pointer bg-white rounded-full;
  }

  &__indicator {
    svg {
      @apply fill-black;
    }
  }

  &__indicators {
    position: relative;
  }

  &__menu {
    @apply mt-1;
  }

  &__value-container {
    @apply overflow-visible;
  }

  &__option {
    @apply font-medium text-violet-800;
    @apply text-xs #{!important};

    input[type="checkbox"] {
      margin-left: 0.312rem !important;
      border-radius: 3px;
      height: 0.813rem;
      width: 0.813rem;
      transition: 0.3s;
      @apply bg-gray-100 border-gray-300;

      &:checked {
        @apply border-indigo-500;
        @apply bg-indigo-500 #{!important};
      }
    }

    &:hover {
      input[type="checkbox"] {
        @apply border-indigo-500 bg-white;
      }
    }
  }
}
// input[type="radio"] {
//   // margin-left: 0.312rem !important;
//   // border-radius: 3px;
//   height: 0.875rem;
//   width: 0.875rem;
//   // transition: 0.3s;
//   @apply bg-gray-100 border-gray-300;

//   &:checked {
//     @apply border-indigo-500;
//     @apply bg-indigo-500 #{!important};
//   }
//   &:hover {
//     @apply border-indigo-500 bg-white;
//   }
// }

/* responsive */
/* mobile */
@media (max-width: 480px) {
  .inventory_select__control {
    @apply pr-2.5;
  }
}

.all_dropdown__menu-notice--no-options,
.table_dropdown__menu-notice--no-options,
.form_multiDropDown__menu-notice--no-options {
  @apply text-sm12 text-left px-3 py-1 #{!important};
}

// .table_dropdown__menu {
//   @apply capitalize;
// }

.table_dropdown__single-value {
  // @apply capitalize;
}

.error-field {
  .table_dropdown__control,
  .table_multiDropdown__control {
    @apply border-rose-500 #{!important};

    .table_dropdown__placeholder,
    .table_multiDropdown__placeholder {
      @apply text-rose-500;
    }
  }

  + .react-tooltip {
    @apply text-rose-500;
  }
}

.login-dd {
  .all_dropdown__indicator {
    width: 24px;
    height: 6px;
    background-image: url("../media/icons/standard_icons/down-angle1.svg") !important;
  }

  .all_dropdown__single-value {
    font-size: 0.875rem !important;
    font-family: "Basier Square", "sans-serif";
  }

  .all_dropdown__control {
    &--menu-is-open {
      .all_dropdown__indicator {
        background-image: url("../media/icons/standard_icons/down-angle-hover1.svg") !important;
      }
    }

    &:hover {
      .all_dropdown__indicator {
        background-image: url("../media/icons/standard_icons/down-angle-hover1.svg") !important;
      }
    }
  }

  .login-dd-value {
    .all_dropdown__control {
      border-color: #6f6cff !important;

      .all_dropdown__placeholder {
        color: #6f6cff;
      }

      .all_dropdown__indicator {
        background-image: url("../media/icons/standard_icons/down-angle-hover1.svg") !important;
      }
    }
  }
}

.right-buy {
  .price-detail {
    .all_dropdown__control {
      @apply border-violet-800 h-7 #{!important};
      &:hover {
        @apply border-indigo-500 #{!important};
      }
      &--menu-is-open {
        @apply border-indigo-500 #{!important};
      }

      .all_dropdown__placeholder {
        @apply text-violet-800;
      }
    }
  }
}

.single_card {
  .bottom_card {
    .card_form {
      input {
        @apply pr-8;
      }
    }
  }
}

.h-TwentyFour {
  .all_dropdown__control,
  .form_multiDropDown__control {
    @apply h-6 #{!important};
  }
}
.form_multiDropDown__control {
  .option-label {
    @apply pointer-events-none;
  }
}

.orderOfferWidget {
  .h-TwentyFour {
    .form_multiDropDown__control {
      min-width: unset;
      .form_multiDropDown__value-container {
        padding-right: 1.125rem;
      }
      .form_multiDropDown__indicators {
        position: absolute;
        right: 0.5rem;
        top: 50%;
        transform: translateY(-50%);
        .form_multiDropDown__indicator {
          padding: 0;
          width: 0.375rem;
        }
      }
    }
    .table-date-icon {
      width: 1.5rem;
      // svg {
      //   width: 0.8125rem;
      //   height: 0.875rem;
      // }
    }
    .dateplaceholder {
      left: 2rem;
      line-height: 1;
      top: -0.3125rem;
    }
    .react-datepicker-wrapper {
      display: flex;
      .picker-input {
        padding-left: 1.875rem;
      }
    }
  }
}
.h-thirty {
  &.whiteFormFields {
    .country-code {
      // .dropdown {
      //   @apply border-gray-300;
      // }
      .all_dropdown__control {
        @apply border-gray-300 #{!important};
        height: 1.875rem !important;
        .all_dropdown__single-value {
          font-size: 0.75rem !important;
        }
      }
      input[name="phone_number"] {
        height: 1.875rem !important;
      }
    }
    &.disable {
      pointer-events: none;
      .dropdown {
        @apply border-gray-300 #{!important};
        .all_dropdown__control {
          @apply border-r-gray-300 #{!important};
          background-color: #f2f2f2 !important;
        }
      }
      input[name="phone_number"] {
        background-color: #f2f2f2 !important;
      }
    }
  }
  .table_dropdown__control {
    min-height: 1.875rem !important;
  }
}

tr.row-indigo-light {
  .table_dropdown__control,
  .table_multiDropdown__control {
    &:not(
        .table_dropdown__control--is-disabled,
        .table_multiDropdown__control--is-disabled
      ) {
      .table_dropdown__dropdown-indicator,
      .table_multiDropdown__indicators {
        background-image: url("../media/icons/standard_icons/select-angle-indigo.svg") !important;
      }
    }
  }
}

@media (min-width: 1440px) {
  .dd_large {
    .form_multiDropDown__menu {
      width: 150%;
    }
    &.rightDD {
      .form_multiDropDown__menu {
        right: 0;
      }
    }
  }
}

.th_last th:last-child {
  .fil_arrow {
    margin-left: 0.9375rem;
    right: unset;
  }
}

.tx-trade-table th:nth-last-child(2) {
  .fil_arrow {
    margin-left: 0.9375rem;
    right: unset;
  }
}

.text-label {
  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: calc(51% + 1px);
    background: #fff;
    z-index: -1;
    transform: translateY(0);
  }
}

.auto_width_row {
  .dropdown_wrap,
  .multi-select-dropdown {
    min-width: 12.5rem;
    // max-width: unset;
    // .table_dropdown__control{
    //   flex-wrap: nowrap;
    //   .table_dropdown__single-value{
    //     overflow: visible;
    //   }
    // }
  }
  .dropdown_wrap {
    @media (max-width: 767px) {
      min-width: 10.5rem;
    }
  }
}
.parList {
  &:hover {
    .table_row:not(.row-error-new) {
      background-color: rgb(111 108 255 / 0.07);
      .st_icons {
        background-color: rgb(111 108 255 / 0.07);
      }
    }
    .ppeBlock {
      background-color: rgb(111 108 255 / 0.07);
    }
  }
}

// .table_row:not(.row-error) {
.table_row:not(.row-error-new) {
  &:hover {
    background-color: rgba(121, 110, 255, 0.07) !important;
    .checkbox_td {
      label {
        background-color: rgba(121, 110, 255, 0.07) !important;
      }
    }
    .st_icons {
      background-color: rgba(121, 110, 255, 0.07) !important;
    }
    + .error-row-track {
      .error-box {
        background-color: rgb(111 108 255 / 0.07);
      }
    }
    + .ppeBlock {
      background-color: rgb(111 108 255 / 0.07);
    }
  }
  &.row-disabled {
    .checkbox_td {
      label {
        @apply bg-gray-100;
      }
    }
  }
}

.top-search {
  .visible-cir {
    @media (min-width: 1330px) {
      @apply absolute  top-0 bg-white;
      min-height: 3.75rem;
      right: 5px;
      &::before {
        content: "";
        position: absolute;
        right: 100%;
        top: 0;
        width: 2.5rem;
        height: 100%;
        background: linear-gradient(-90deg, white, transparent);
      }
    }
  }
}

#filters_block {
  .all_dropdown,
  .form_multiDropDown {
    &__control {
      min-width: auto !important;
    }
  }
  .form_multiDropDown__menu-list {
    @apply scrollbar-thumb-rounded-full scrollbar-thumb-violet-800;
  }
  .form_multiDropDown__menu-list::-webkit-scrollbar {
    width: 0.3125rem;
  }
}
.inventory-table {
  .table_multiDropdown__control {
    .table_multiDropdown__placeholder {
      @apply whitespace-nowrap overflow-hidden text-ellipsis;
    }
  }
}

.error-field {
  .all_dropdown__control,
  .table_multiDropdown__control {
    @apply border-rose-500 #{!important};

    // .all_dropdown__placeholder,
    // .table_multiDropdown__placeholder {
    //   @apply text-rose-500;
    // }
  }

  + .react-tooltip {
    @apply text-rose-500;
  }
}

.hideddlabel {
  .all_dropdown__control {
    &.all_dropdown__control--menu-is-open {
      .all_dropdown__placeholder {
        display: none !important;
      }
    }
    .all_dropdown__value-container.all_dropdown__value-container--has-value
      .all_dropdown__placeholder {
      display: none !important;
    }
  }
}

.offermodelfooter {
  .all_dropdown__control {
    height: 1.5rem;
  }
}

.yellowBorder {
  .all_dropdown__control {
    @apply border-yellow-100 #{!important};
  }
}

.offercontent {
  .formBlock {
    .singleFOrmBLock {
      .priceDis {
        a,
        input {
          @apply bg-gray-100/50 #{!important};
        }
        input {
          @apply border-0 border-l;
        }
      }
    }
  }
}

.tableSearchField__input-container.css-19bb58m,
.tableSearchField__input-container.css-1cfo1cf {
  margin: 0px !important;
  padding-bottom: 0px !important;
  padding-top: 0px !important;
}

@media (min-width: 1600px) and (max-width: 2100px) {
  .trade-filters.trade-inventory-filter-cont {
    flex-wrap: wrap !important;
    .trade-inventory-filter {
      max-width: 12rem;
    }
  }
}
