/* react-datepicker */
.react-datepicker {
  @apply font-sans shadow-md border-none p-2.5;
  &__header {
    @apply bg-white border-none pt-1.5 pb-0;
  }
  &__day-name {
    @apply text-gray-400;
  }
  &__day-names {
    @apply mb-0;
  }
  &__triangle {
    @apply hidden;
  }
  &__input-container {
    input {
      height: 1.875rem;
      @apply border border-gray-300 rounded max-w-full;
      padding-left: 2.4375rem;
    }
  }
  &__current-month {
    @apply text-sm13 font-semibold;
  }
  &__navigation {
    @apply h-5 w-5 top-4;
    &-icon {
      @apply inset-auto flex items-center justify-center h-5 w-5;
      font-size: 0;
      &:before {
        width: 0.4375rem;
        height: 0.4375rem;
        @apply transition left-auto right-auto border border-violet-500;
        @apply border-l-transparent #{!important};
        @apply border-b-transparent #{!important};
      }
      &:hover {
        &:before {
          @apply border-indigo-500;
        }
      }
    }
    &--previous {
      @apply left-4;
      &::before {
        @apply right-2;
      }
    }
    &--next {
      @apply right-4;
      &::before {
        @apply right-2;
      }
    }
  }
  &__day {
    min-width: 1.875rem;
    min-height: 1.875rem;
    @apply inline-flex items-center justify-center font-medium text-xs leading-7 m-0.5 relative;
    @apply rounded-full #{!important};
    &--today {
      outline: none !important;
      @apply font-bold;
      &:hover {
        @apply bg-indigo-500/30;
      }
    }
    &--selected {
      @apply bg-indigo-500;
      &:hover {
        @apply bg-indigo-500;
      }
    }
    &--keyboard-selected {
      @apply bg-indigo-500/30;
      &:hover {
        @apply bg-indigo-500/30;
      }
    }
    &-name {
      @apply text-xs font-medium mx-1;
    }
    &--in {
      &-selecting-range,
      &-range {
        @apply text-violet-800 bg-transparent #{!important};
        &:before {
          @apply bg-indigo-500 bg-opacity-[0.2];
          content: "";
          position: absolute;
          left: -2px;
          top: 0;
          width: calc(100% + 4px);
          height: 100%;
          z-index: 0;
        }
      }
      // &-range{
      //   @apply bg-transparent text-violet-800;
      //   &:before{
      //     @apply bg-indigo-500 bg-opacity-[0.2];
      //     content: '';
      //     position: absolute;
      //     left: -2px;
      //     top: 0;
      //     width: calc(100% + 4px);
      //     height: 100%;
      //     z-index: 0;
      //   }
      // }
    }
    &--range-start {
      @apply bg-indigo-500 text-white #{!important};
      &:before {
        @apply rounded-l-full;
        left: 0;
        width: calc(100% + 2px);
      }
    }
    &--range-end {
      @apply bg-indigo-500 text-white #{!important};
      &:before {
        @apply rounded-r-full;
        right: 0;
        width: calc(100% + 2px);
      }
    }
    &--selecting-range {
      &-start {
        @apply bg-indigo-500 text-white #{!important};
        &:before {
          @apply rounded-l-full;
          left: 0;
          width: calc(100% + 2px);
        }
      }
      &-end,
      &--range-end {
        @apply bg-indigo-500 text-white #{!important};
        &:before {
          @apply rounded-r-full;
          right: 0;
          width: calc(100% + 2px);
        }
      }
    }
  }
  &__current-month {
    @apply mb-2;
  }
  &-popper {
    z-index: 30;
    @apply pt-0 #{!important};
  }
  &__children-container {
    @apply w-auto m-0 p-0;
  }
  &--time-only {
    @apply px-0 #{!important};
  }
  &__time-list {
    @apply overflow-y-auto scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full;
    &-item {
      @apply font-medium;
      &:hover {
        @apply bg-indigo-500 bg-opacity-[7%] text-indigo-500 #{!important};
      }
      &--selected {
        @apply bg-white text-indigo-500 font-medium #{!important};
      }
    }
  }
  &-wrapper {
    width: 100%;
  }
  &__input-time-container {
    .react-datepicker-time__input {
      input {
        @apply ml-0 p-1.5 rounded border-gray-300 text-sm12 leading-4 #{!important};
      }
    }
  }
}
.dateWithTime {
  .react-datepicker__input-container {
    input {
      height: 2.5rem;
    }
  }
}
tr {
  td {
    .react-datepicker-wrapper {
      input {
        height: 1.5rem;
      }
    }
  }
}

.req-icon-block {
  .react-datepicker__input-container {
    input {
      padding-left: 3.4375rem;
    }
  }
  .group .peer:placeholder-shown ~ .peer-placeholder-shown\:text-xs {
    left: 3rem;
  }
  span {
    + input {
      padding-left: 3.4375rem;
    }
  }
}

.hideIcon {
  .react-datepicker-wrapper {
    input {
      @apply pl-2.5;
    }
  }
}
